<template>
  <div class="azure-overlay">
    <well class="w-full sm:w-136">
      <h1 class="text-lg font-semibold">{{ $t(`azure.${status}`) }}</h1>
      <LoadingSpinner
        :is-loading="status !== statusMap.error"
        class-names="white mt-4"
      />
      <btn
        v-if="status === statusMap.error"
        class="mt-8 w-full"
        theme="secondary"
        @click="reload"
      >
        {{ $t(`azure.reload`) }}
      </btn>
    </well>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import Well from '@/components/Well/Well';
import Btn from '@/components/Button/Button';

export default {
  components: { Well, LoadingSpinner, Btn },
  computed: {
    ...mapState('azure', ['status', 'statusMap']),
  },
  methods: {
    reload() {
      this.$router.go();
    },
  },
};
</script>
<style scoped>
.azure-overlay {
  @apply bg-white fixed h-screen w-screen top-0 left-0 z-100 p-4 flex flex-col items-center justify-center bg-cover text-primary text-center;
  background-image: url('../../assets/background.png');
}
</style>
